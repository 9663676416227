import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "col-4 text-center" }
const _hoisted_3 = { class: "text-primary mb-0" }
const _hoisted_4 = {
  key: 0,
  class: "matchCursor"
}
const _hoisted_5 = {
  key: 1,
  class: "matchCursor"
}
const _hoisted_6 = { class: "away-wrapper col-4 text-center px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "home")
    ]),
    _createElementVNode("div", {
      class: "score-wrapper text-center col-4",
      onClick: _cache[0] || (_cache[0] = () => _ctx.$router.push({ path: `/competitions/${_ctx.id.competitionId}/${_ctx.id.subCompetitionId}/${_ctx.id.competitionSectionId}/${_ctx.id.versusMatchId}` }))
    }, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.matchName), 1),
      (_ctx.score.home !== null)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.score.home ?? 0) + "-" + _toDisplayString(_ctx.score.away), 1))
        : (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.language==='th'?"ไม่มีข้อมูล":"No data"), 1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "away")
    ])
  ]))
}