import { axios } from '@/_modules/axios'
import { BaseResponse, BasePagination, Staff, QueryParams, BannerInfoFilter, BaseSingleResponse, StaffImage, Honor, BaseData } from '@/_modules/types'
// interface StaffParam extends QueryParams {
//   // injuries: boolean;
// }


const list = async (params?: QueryParams): Promise<BaseResponse<BasePagination<Staff[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<Staff[]>>>('/api/v1/staff/overview/all', { params })
  return data
}

const getById = async (id: number): Promise<BaseResponse<BaseSingleResponse<Staff>>> => {
  const url = `api/v1/staff/${id}`
  const { data: bannerData } = await axios.get<BaseResponse<BaseSingleResponse<Staff>>>(`${url}/banner`)
  const { data: infoData } = await axios.get<BaseResponse<BaseSingleResponse<Staff>>>(`${url}/information`)
  const { account: infoAccount } = infoData.data
  const { account: bannerAccount } = bannerData.data
  const mergedData = {
    account: {
      ...infoAccount,
      ...bannerAccount
    }
  }
  bannerData.data = mergedData
  return bannerData
}

const getHonorNation = async (id: number, params: QueryParams) => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get(`${url}/honors/nation`, { params })
  return data
}


const getHonorClub = async (id: number, params: QueryParams) => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get(`${url}/honors/club`, { params })
  return data
}


const getHonorPersonal = async (id: number, params: QueryParams) => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get(`${url}/honors/personal`, { params })
  return data
}

const getPenaltyCurrent = async (id: number, params: QueryParams) => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get(`${url}/penalties/current`, { params })
  return data
}

const getPenalty = async (id: number, params: QueryParams) => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get(`${url}/penalties`, { params })
  return data
}


const getImages = async (id: number, params: QueryParams): Promise<BaseResponse<BasePagination<StaffImage[]>>> => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get<BaseResponse<BasePagination<StaffImage[]>>>(`${url}/images`,
    { params })
  return data
}


const getVideos = async (id: number, params: QueryParams): Promise<BaseResponse<BasePagination<StaffImage[]>>> => {
  const url = `api/v1/staff/${id}`
  const { data } = await axios.get<BaseResponse<BasePagination<StaffImage[]>>>(`${url}/video`,
    { params })
  return data
}

const getTeamHistoryNation = async (id: number, params?: QueryParams): Promise<any> => {
  const url = `api/v1/staff/${id}/team_history`
  const { data: teamHistoryNation } = await axios.get<any>(`${url}/nation`, { params })
  return teamHistoryNation
}


const getTeamHistoryClub = async (id: number, params?: QueryParams): Promise<any> => {
  const url = `api/v1/staff/${id}/team_history`
  const { data: teamHistoryClub } = await axios.get<any>(`${url}/club`, { params })
  return teamHistoryClub
}

export const staffService = {
  list,
  getById,
  getImages,
  getVideos,
  getHonorNation,
  getHonorClub,
  getHonorPersonal,
  getPenaltyCurrent,
  getPenalty,
  getTeamHistoryNation,
  getTeamHistoryClub
}
