
import { defineComponent } from "vue";
import Icon from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    name: String,
    style: {
      type: Object,
      require: false,
    },
    cssClass: {
      type: String,
      require: false,
    },
  },
});
