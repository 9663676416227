import { axios } from '@/_modules/axios'
import { BaseResponse, BasePagination, Competition, CompetitionQueryDto, BaseSingleResponse, SubCompetitionQueryParamsDto, SubCompetition, Team, TeamInSubCompetitionQueryParamsDto, CompetitionSection, QueryParams, SubCompetitionImage, CompetitionType, IVersusMatch } from '@/_modules/types'

const url = '/api/v1/competition/overview'

// ถูก

const list = async (params?: CompetitionQueryDto): Promise<BaseResponse<BasePagination<Competition[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<Competition[]>>>(url, { params })
  if (data.data) {
    return data.data as unknown as BaseResponse<BasePagination<Competition[]>>
  }
  return data
}
// ถูก

const getById = async (id: number): Promise<BaseResponse<BaseSingleResponse<Competition>>> => {
  const { data } = await axios.get<BaseResponse<BaseSingleResponse<Competition>>>(`/api/v1/competition/${id}/information`)
  return data
}
// ถูก
const getByLevel = async (level: CompetitionType, params: QueryParams) => {
  const { data } = await axios.get<BaseResponse<BasePagination<Competition[]>>>(`/api/v1/competition/competition-level/${level}`, { params })
  return data
}
// ถูก
const getSubCompetitions = async (competitionId: number, params: SubCompetitionQueryParamsDto): Promise<BaseResponse<BasePagination<SubCompetition[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<SubCompetition[]>>>(`/api/v1/competition/${competitionId}/sub-competition`, { params })
  // if (data.data) {
  //   return data.data as unknown as BaseResponse<BasePagination<Competition[]>>
  // }
  return data
}

// คนละ API
const getSubCompetition = async (competitionId: number, subCompetitionId: number) => {
  const url = `/api/v1/competitions/${competitionId}/sub-competitions/${subCompetitionId}`
  const { data } = await axios.get<BaseResponse<BaseSingleResponse<SubCompetition>>>(`/api/v1/competitions/${competitionId}/sub-competitions/${subCompetitionId}`)
  return data
}

// ถูก
const getTeams = async (competitionId: number, subCompetitionId: number, params: TeamInSubCompetitionQueryParamsDto) => {
  const { data } = await axios.get<BaseResponse<BasePagination<Team[]>>>(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/team-in-sub-competition`, { params })
  return data
}

// ถูก
const getCompetitionSections = async (competitionId: number, subCompetitionId: number) => {
  const { data } = await axios.get<BaseResponse<BaseSingleResponse<CompetitionSection[]>>>(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section`)
  return data
}

// ถูก
const getGroupStages = async (competitionId: number, subCompetitionId: number, sectionId: number) => {
  const { data } = await axios.get(`/api/v1/competition/${competitionId}/sub-competitions/${subCompetitionId}/competition-sections/${sectionId}/group-stage`)
  return data
}

const getKnoctoutFinal = async (competitionId: number, subCompetitionId: number) => {
  const { data } = await axios.get(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section/final`)
  return data
}

const getKnoctoutSemiFinal = async (competitionId: number, subCompetitionId: number) => {
  const { data } = await axios.get(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section/semi-final`)
  return data
}

const getKnoctoutThirdPlaceMatch = async (competitionId: number, subCompetitionId: number) => {
  const { data } = await axios.get(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section/third-place-match`)
  return data
}

const getKnoctoutThirdRound = async (competitionId: number, subCompetitionId: number) => {
  const { data } = await axios.get(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section/third-round`)
  return data
}
const getKnoctoutSecondRound = async (competitionId: number, subCompetitionId: number) => {
  const { data } = await axios.get(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section/second-round`)
  return data
}

// ถูก
const getImages = async (competitionId: number, subCompetitionId: number, params: QueryParams) => {
  const { data } = await axios.get<BaseResponse<BasePagination<SubCompetitionImage[]>>>(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/images`, { params })
  return data
}
// ถูก
const getVideos = async (competitionId: number, subCompetitionId: number, params: QueryParams) => {
  const { data } = await axios.get<BaseResponse<BasePagination<SubCompetitionImage[]>>>(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/videos`, { params })
  return data
}

const getVersusMatch = async (competitionId: number, subCompetitionId: number, competitionSectionId: number, versusMatchId: number) => {
  const { data } = await axios.get<BaseResponse<any[]>>(`/api/v1/competition/${competitionId}/sub-competition/${subCompetitionId}/competition-section/${competitionSectionId}/versus-match/${versusMatchId}`)
  return data
}

export const competitionService = {
  list,
  getById,
  getByLevel,
  getSubCompetitions,
  getSubCompetition,
  getTeams,
  getCompetitionSections,
  getGroupStages,
  getImages,
  getVideos,
  getVersusMatch,
  getKnoctoutFinal,
  getKnoctoutSemiFinal,
  getKnoctoutThirdPlaceMatch,
  getKnoctoutThirdRound,
  getKnoctoutSecondRound
}
