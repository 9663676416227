import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30afa86f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sat-card-cover position-relative py-3" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "bg-white" }
const _hoisted_5 = { class: "d-flex justify-content-between mb-1" }
const _hoisted_6 = { class: "d-flex justify-content-between mb-3" }
const _hoisted_7 = { id: "sub-tooltip" }
const _hoisted_8 = { class: "text-nowrap" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "small" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, {
      hoverable: "",
      class: "team position-relative"
    }, {
      cover: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (
              _ctx.data?.setting_team_informations?.setting_team_media_profile_url ||
              _ctx.data?.logo
            )
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "posotion-absolute object-fit-contain h-100",
                alt: "example",
                src: 
              _ctx.data?.setting_team_informations?.setting_team_media_profile_url ??
              _ctx.data?.logo
            
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: require('@/assets/logo-placeholder.svg?raw'),
                class: "posotion-absolute object-fit-contain h-100"
              }, null, 8, _hoisted_3))
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.data?.id)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `/teams/${_ctx.data?.id}`,
              class: "stretched-link"
            }, null, 8, ["to"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_typography_text, {
              style: {"width":"180px"},
              class: "h6 text-primary",
              ellipsis: 
              _ctx.data?.setting_team_informations
                ?.setting_team_information_name_th ?? _ctx.data?.name?.th
            ,
              content: 
              _ctx.data?.setting_team_informations
                ?.setting_team_information_name_th ??
              _ctx.data?.name?.th ??
              '-'
            
            }, null, 8, ["ellipsis", "content"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_a_typography_paragraph, {
                class: "paragraph-no-margin",
                style: {"width":"70px"},
                ellipsisTooltip: "",
                ellipsis: 
                _ctx.data?.setting_team_informations
                  ?.setting_team_information_name_en ?? _ctx.data?.name?.en
              ,
                content: 
                _ctx.data?.setting_team_informations
                  ?.setting_team_information_name_en ??
                _ctx.data?.name?.en ??
                '-'
              
              }, null, 8, ["ellipsis", "content"])
            ]),
            _createElementVNode("span", _hoisted_8, [
              (_ctx.store.state.currentLanguage === 'th')
                ? (_openBlock(), _createElementBlock("label", _hoisted_9, [
                    _createVNode(_component_a_typography_paragraph, {
                      class: "paragraph-no-margin",
                      style: {"width":"70px"},
                      ellipsisTooltip: "",
                      ellipsis: 
                  _ctx.data.setting_team_region_country?.country_name_th ??
                  _ctx.data?.country_th
                ,
                      content: 
                  _ctx.data.setting_team_region_country?.country_name_th ??
                  _ctx.data?.country_th
                
                    }, null, 8, ["ellipsis", "content"])
                  ]))
                : (_openBlock(), _createElementBlock("label", _hoisted_10, [
                    _createVNode(_component_a_typography_paragraph, {
                      class: "paragraph-no-margin",
                      style: {"width":"70px"},
                      ellipsisTooltip: "",
                      ellipsis: 
                  _ctx.data.setting_team_region_country?.country_name_en ??
                  _ctx.data?.country_en
                ,
                      content: 
                  _ctx.data.setting_team_region_country?.country_name_en ??
                  _ctx.data?.country_en
                
                    }, null, 8, ["ellipsis", "content"])
                  ]))
            ])
          ]),
          _createElementVNode("p", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.t("team.type")) + ": ", 1),
            (_ctx.store.state.currentLanguage === 'th')
              ? (_openBlock(), _createElementBlock("label", _hoisted_12, _toDisplayString(_ctx.data?.global_config_local_types?.global_config_local_type_name_th ??
            _ctx.data?.nationCategory), 1))
              : (_openBlock(), _createElementBlock("label", _hoisted_13, _toDisplayString(_ctx.data?.global_config_local_types?.global_config_local_type_name_en ??
            _ctx.data?.nationCategoryEN), 1))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}