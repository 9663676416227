
import { defineComponent, PropType, toRefs } from 'vue'

export default defineComponent({
  props: {
    score: {
      type: Object as PropType<{home: number; away: number}>
    },
    matchName: String,
    id:{
      type:Object
    },
    language:String
  },
  setup (props) {
    return {
      ...toRefs(props)
    }
  }
})
