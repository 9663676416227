import { axios } from '@/_modules/axios'
import { BaseResponse, BasePagination, Stadium, StadiumQueryDto, BaseSingleResponse } from '@/_modules/types'

const url = '/api/v1/stadiums'

const list = async (params?: StadiumQueryDto): Promise<BaseResponse<BasePagination<Stadium[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<Stadium[]>>>(`${url}/overview`, { params })
  if (data.data) {
    return data.data as unknown as BaseResponse<BasePagination<Stadium[]>>
  }
  return data
}

const getById = async (id: number): Promise<BaseResponse<BaseSingleResponse<Stadium>>> => {
  const { data } = await axios.get<BaseResponse<BaseSingleResponse<Stadium>>>(`${url}/${id}/information`)
  return data
}

export const stadiumService = {
  list,
  getById
}
