
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    latestMatch: Object,
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    return {
      t,
      store,
    };
  },
});
