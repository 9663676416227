import { format } from "date-fns";

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// const getKeyValue = <T extends object, U extends keyof T>(key: U) => (obj: T) => obj[key];
// const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key];
const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

const injuriesLevel = (level: string) => {
    switch (level) {
        case 'MINOR':
            return {
                th: 'เล็กน้อย',
                fullTH: 'บาดเจ็บเล็กน้อย',
                en: 'Minor',
                fullEN: 'Minor',
                css: 'text-warning-light',
                color: '#267FFF'
            };
        case 'MODERATE':
            return {
                th: 'ปานกลาง',
                fullTH: 'บาดเจ็บปานกลาง',
                en: 'Moderate',
                fullEN: 'Moderate',
                css: 'text-warning',
                color: '#FEAC1A'
            };
        case 'SERIOUSLY':
            return {
                th: 'หนัก',
                fullTH: 'บาดเจ็บสาหัส',
                en: 'Seriously',
                fullEN: 'Seriously',
                css: 'text-danger',
                color: '#EA5455'
            };
        default:
            return {
                th: '-',
                fullTH: '-',
                en: '-',
                fullEN: '-',
                css: 'text-normal',
                color: '-'
            };
    }
};

const EDurationUnit = (unit: string) => {
    switch (unit) {
        case "DAYS":
            return {
                en: "days",
                th: "วัน",
            };
        // return "วัน";
        case "WEEKS":
            return {
                en: "weeks",
                th: "สัปดาห์",
            };
        case "MONTHS":
            return {
                en: "months",
                th: "เดือน",
            };
        case "YEARS":
            return {
                en: "years",
                th: "ปี",
            };
        case "MATCHES":
            return {
                en: "matches",
                th: "แมทช์",
            };
        case "SEASONS":
            return {
                en: "seasons",
                th: "ฤดูกาล",
            };
        default:
            return {
                en: "",
                th: "",
            };
    }
};

const CauseUnit = (cause: string) => {
    switch (cause) {
        case 'COMPETITION':
            return {
                en: "Competition",
                th: "การแข่งขัน",
            };
        case 'TRAINING':
            return {
                en: "Training",
                th: "การซ้อม",
            };
        case 'OTHERS':
            return {
                en: "Others",
                th: "อื่นๆ",
            };
        default:
            return {
                en: "",
                th: ""
            }
    }
}

const uniqueArray = (arr: Array<any>, key: string) => [
    ...new Map(arr.map(item => [item[key], item])).values()
];

const sortArrayByKey = (arr: Array<any>, key: string, point: string) => {
    // ascending: asc, descending: des
    if (point === 'asc') {
        return arr.sort((a, b) => {
            if (a[key] < b[key]) return -1;
            return a[key] > b[key] ? 1 : 0;
        });
    } else {
        return arr.sort((a, b) => {
            if (b[key] < a[key]) return -1;
            return a[key] > b[key] ? 1 : 0;
        });
    }
};

const uniformType = (type: string) => {
    switch (type) {
        case 'HOME':
            return {
                th: 'ชุดทีมเหย้า',
                en: 'Home Team'
            }
        case 'AWAY':
            return {
                th: 'ชุดทีมเยือน',
                en: 'Away Team'
            }
        case 'SPARE':
            return {
                th: 'ชุดทีมสำรอง',
                en: 'Spare Team'
            }
        default:
            return {
                th: '-',
                en: '-'
            }
    }
};

const scoreResult = (scoreOne: string, scoreTwo: string, language = 'th') => {
    // lose win draw
    const win = {
        th: 'ชนะ',
        en: 'win',
        classStyle: 'text-success'
    };
    const lose = {
        th: 'แพ้',
        en: 'lose',
        classStyle: 'text-error'
    };
    const draw = {
        th: 'เสมอ',
        en: 'draw',
        classStyle: ''
    };
    const nodata = {
        th: 'ไม่มีข้อมูล',
        en: 'No data ',
        classStyle: ''

    }
    if (scoreOne === null || scoreTwo === null) {
        return nodata
    } else {
        if (+scoreOne === +scoreTwo) {
            return draw;
        } else if (+scoreOne > +scoreTwo) {
            return win;
        } else {
            return lose;
        }
    }

};

const formatDate = (date: any) => { return format(new Date(date), 'dd/MM/yyyy') }

const checkWindowSlide = () => {
    switch (true) {
        case window.innerWidth <= 480:
            return 2
        case window.innerWidth <= 768:
            return 3
        case window.innerWidth <= 1024:
            return 3
        default: return 5
    }
}

const checkWindowRowCol = () => {
    switch (true) {
        case window.innerWidth <= 480:
            return `row-cols-2`
        case window.innerWidth <= 768:
            return `row-cols-3`
        case window.innerWidth <= 1024:
            return `row-cols-3`
        default: return `row-cols-5`
    }
}

const checkWindowReferee = () => {
    switch (true) {
        case window.innerWidth <= 480:
            return `col-12`
        case window.innerWidth <= 768:
            return `col-8 mb-3`
        case window.innerWidth <= 1024:
            return `col-4`
        default: return `col-3`
    }
}
export const Helpers = {
    getRandomInt,
    getKeyValue,
    injuriesLevel,
    EDurationUnit,
    CauseUnit,
    uniqueArray,
    sortArrayByKey,
    uniformType,
    scoreResult,
    formatDate,
    checkWindowSlide,
    checkWindowRowCol,
    checkWindowReferee
};
