
import { defineComponent, watch, reactive, toRefs, ref, onMounted } from "vue";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { DownOutlined, GlobalOutlined } from "@ant-design/icons-vue";
import { useCookie } from "@vue-composable/cookie";
import { MenuInfo } from "ant-design-vue/lib/menu/src/interface";
import { useStore } from "vuex";
import SatIcon from "@/components/SatIcon.vue";
import { Modal } from "ant-design-vue";
import set from "date-fns/set/index";
// import SatIcon from '@/components/SatIcon.vue'
interface OwnState {
  ssoRoute: string;
  routes: RouteLocationRaw[];
  currentSelectedMenu: string[];
  visible: boolean;
  ModalText: string;
  confirmLoading: boolean;
  checkedLang: boolean;
}
export default defineComponent({
  components: {
    DownOutlined,
    GlobalOutlined,
    SatIcon,
    Modal,
  },
  setup(props) {
    const { cookie, setCookie } = useCookie(process.env.VUE_APP_COOKIE_KEY);
    const store = useStore();

    const { t, availableLocales, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const anotherLocale = ref(availableLocales.find((l) => l !== locale.value));
    const route = useRoute();
    const router = useRouter();

    const state = reactive<OwnState>({
      ssoRoute: "",
      routes: router.options.routes.filter((ele) => ele.meta?.visible),
      currentSelectedMenu: [String(route.name).toLowerCase()],
      visible: false,
      ModalText: "",
      confirmLoading: false,
      checkedLang: false,
    });
    // onMounted(() => {
    // const cookies = localStorage?.getItem('bdadmin') as string
    //   setCookie(cookies)
    // });
    const handleChangeLocale = (v: string) => {
      locale.value = v;
      anotherLocale.value = availableLocales.find((l) => l !== locale.value);
      localStorage.setItem("language", locale.value);
      store.dispatch("setLanguage", locale.value);
    };

    const showModal = () => {
      state.visible = !state.visible;
    };

    const changeRoute = (path: string) => {
      router.push(path);
      state.visible = !state.visible;
    };

    const changeLanguage = (checked: any) => {
      state.checkedLang = !state.checkedLang;
      if (checked) {
        handleChangeLocale("en");
      } else {
        handleChangeLocale("th");
      }
    };

    const handleOk = (e: any) => {
      state.ModalText = "The modal will be closed after two seconds";
      state.confirmLoading = true;
      setTimeout(() => {
        state.visible = false;
        state.confirmLoading = false;
      }, 2000);
    };

    const close = (e: any) => {
      state.visible = false;
    };
    watch(
      () => route.name,
      (newName) => {
        if (newName) {
          localStorage.setItem("language", "th");
          state.currentSelectedMenu = [String(newName).toLowerCase()];
          const token = localStorage.getItem("token");
          if (!cookie.value && !token && token !== undefined) {
            router.replace({ name: "Disallowed" });
          } else if (token === undefined) {
            router.replace({ name: "Disallowed" });
          }
        }
      },
      { immediate: true }
    );
    function clickOutSideMemu(event: any) {
      const menu: any = document.getElementById("menuIcon");
      if (!menu.contains(event.target)) {
        state.visible = false;
      }
    }

    onMounted(() => window.addEventListener("click", clickOutSideMemu));

    watch(
      () => cookie.value,
      (cookieValue) => {
        if (cookieValue) {
          state.ssoRoute = cookieValue;
          // router.replace({ name: 'Disallowed' })
        }
      },
      { immediate: true }
    );
    return {
      t,
      availableLocales,
      anotherLocale,
      locale,
      cookie,
      handleChangeLocale,
      ...toRefs(state),
      showModal,
      handleOk,
      changeRoute,
      changeLanguage,
    };
  },
});
