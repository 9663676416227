
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  ref,
  PropType,
  computed,
  watch,
  onUpdated,
  nextTick,
} from "vue";
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import SatCard from "./SatCard.vue";
import { FootballPosition, IAthlete } from "@/_modules/types";
import useAthleteRepositories from "@/_composables/useAthleteRepositories";
import useStaffRepositories from "@/_composables/useStaffRepositories";

type Type =
  | "staffs"
  | "athletes"
  | "injured"
  | "team"
  | "tournament"
  | "stadium";
export default defineComponent({
  props: {
    type: {
      type: String as PropType<Type>,
      required: true,
      // default: () => 'athlete',
      // validator: (value) => ['athlete', 'staff'].includes(`${value}`)
    },
    max: {
      type: Number,
      required: false,
      default: 4,
    },
    filter: {
      type: String as PropType<FootballPosition>,
      required: false,
    },
    teamsId: {
      required: false,
      type: Array as PropType<number[]>,
    },
    onFetchData: {
      type: Function,
      required: false,
    },
    id: Number,
    queryFilter: {
      require: false,
      type: Object,
    },
    onClickCard: {
      type: Function,
      required: false,
    },
  },
  components: {
    LeftOutlined,
    RightOutlined,
    SatCard,
    LoadingOutlined,
  },
  setup(props) {
    const { onFetchData, id, filter } = props;
    const slick = ref(null) as any;
    const type = computed(() => props.type);
    const teamsId = computed(() => Object.values(props?.teamsId ?? []));
    // let loading: any = computed(() => Object.values(props?.loading ?? false))
    const state = reactive({
      list: [] as any,
      loading: true,
      arrowLoading: false,
      currentPage: 1,
      lastPage: 1,
      size: 5,
      isFull: false,
    });
    const {
      repositories: staffRepos,
      getStaffRepositories,
      loading: fetchingStaffs,
    } = useStaffRepositories();

    const {
      repositories: athleteRepos,
      getAthleteRepositories,
      loading: fetchingAthletes,
    } = useAthleteRepositories();

    const onSlideChange = (current: number) => {
      // console.log(current);
    };
    const onClickCards = (data: any) => {
      console.log("data", data);
    };
    onMounted(async () => {
      switch (props.type) {
        case "athletes": {
          if (!fetchingAthletes.value && filter) {
            await onFetchAthlete(state.lastPage, props.max);
          }
          break;
        }
        case "injured": {
          if (!fetchingAthletes.value) {
            await getAthleteRepositories({ page: 1, size: 5, injuries: true });
            state.list = athleteRepos.value;
          }
          break;
        }
        case "staffs": {
          if (!fetchingStaffs.value) {
            await getStaffRepositories({ page: 1, size: 5 });
            state.list = staffRepos.value;
          }
          break;
        }
        default:
          break;
      }
      state.loading = false;
    });

    // onUpdated(() => {
    //   const { teamsId } = props;
    //   console.log(
    //     `SatCardCarousel onUpdated :: list ${props.filter} :: ${props.id}`,
    //     {
    //       list: state.list,
    //       teamsId,
    //       props,
    //     }
    //   );
    // });

    const onFetchAthlete = async (page: number, size: number) => {
      // loading = true

      const res =
        ((await getAthleteRepositories({ page, size }, filter)) as []) || [];
      if (!res || (res && res.length < props.max)) {
        state.isFull = true;
        // state.currentPage -= 1;
        // state.lastPage -= 1;
        state.list = [...state.list, ...res];
      } else {
        state.list = [...state.list, ...res];
      }
    };

    watch(
      () => teamsId.value,
      (newValue) => {
        if (newValue?.length) {
          // state.list = state.list.filter((ele: { teamsId: number[] }) =>
          //     !ele.teamsId.some((id: number) => newValue.includes(id))
          // );
          state.list = newValue;
        } else if (type.value === "athletes") {
          state.list = athleteRepos.value;
        } else if (type.value === "staffs") {
          state.list = staffRepos.value;
        } else {
          // console.log("newValue :: else", newValue);
        }
      },
      { immediate: true }
    );

    const onPreviousVideo = async () => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
        await nextTick();
        await slick.value.prev();
      }
      // console.log("onPreviousVideo :: ", state);
    };

    const onNextVideo = async () => {
      let resLength = 0;
      if (state.currentPage === state.lastPage) {
        state.lastPage += 1;
        state.arrowLoading = true;
        switch (type.value) {
          case "athletes":
            if (filter) {
              await onFetchAthlete(state.lastPage, props.max);
            } else if (onFetchData) {
              const res = await onFetchData({
                page: state.lastPage,
                size: state.size,
              });
              if (!res || (res && res.length < 5)) {
                state.isFull = true;
              }
            }
            break;
          case "team":
            if (onFetchData) {
              const { queryFilter } = props;
              const res = await onFetchData({
                ...queryFilter,
                page: state.lastPage,
                size: state.size,
                isNext: true,
                isFilter: false,
              });
              if (res?.team_player_history?.data.length < 5) {
                state.isFull = true;
                resLength = res?.team_player_history?.data.length;
              }
            }
            break;
          default:
            break;
        }
        state.arrowLoading = false;
        if (state.isFull === true && type.value === "team" && resLength === 0) {
          state.lastPage -= 1;
          state.currentPage = state.lastPage;
        } else {
          state.currentPage = state.lastPage;
        }
        // state.currentPage = state.lastPage;
      } else {
        state.currentPage += 1;
      }
      await nextTick();
      slick.value.next();
    };

    return {
      ...toRefs(state),
      onSlideChange,
      onPreviousVideo,
      onNextVideo,
      slick,
      onClickCards,
    };
  },
});
