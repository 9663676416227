import { ref, Ref } from 'vue'
import { useRequest } from 'vue-request'
import { BaseResponse, Staff, IStaff, BasePagination, QueryParams, IHonor, IImage, IVideo, StaffImage } from '@/_modules/types'
import { staffService } from '@/_services'
interface ReturnType {
  repositories: Ref<IStaff[] | null>
  loading: Ref<boolean>
  // getStaffRepositories: (q: QueryParams) => Promise<BasePagination<IStaff[]> | null>
  getStaffRepositories: (q: QueryParams) => Promise<any | null>

  getStaff: (id: number) => Promise<IStaff | null>
  getImages: (id: number, params: QueryParams) => Promise<BasePagination<IImage[]> | null>;
  getVideos: (id: number, params: QueryParams) => Promise<BasePagination<IVideo[]> | null>;
  // getHonorNation: (id: number, params: QueryParams) => Promise<BasePagination<IHonor[]> | null>
  getHonorNation: (id: number, params: QueryParams) => Promise<any | null>
  getHonorPersonal: (id: number, params: QueryParams) => Promise<any | null>
  getHonorClub: (id: number, params: QueryParams) => Promise<any | null>

  getPenaltiesCurrent: (id: number, params: QueryParams) => Promise<any | null>
  getPenalties: (id: number, params: QueryParams) => Promise<any | null>

  getTeamHistoryNation: (id: number, q: QueryParams) => Promise<any | null>
  getTeamHistoryClub: (id: number, q: QueryParams) => Promise<any | null>

}
export default function useStaffRepositories(): ReturnType {
  const repositories = ref<IStaff[]>([])

  const cb = (ele: Staff) => {
    if (!ele) {
      return {} as IStaff
    }
    const { account_informations, account_id, global_config_positions: positions, team_club, team_national, midical_conditions, social_contacts, account_education, global_config_departments: departments } = ele
    const {
      account_information_firstname_th: firstNameTh,
      account_information_lastname_th: lastNameTh,
      account_information_firstname_en: firstName,
      account_information_lastname_en: lastName,
      account_information_age: age,
      account_information_nationality: nationallity,
      account_information_nationality_en: nationalityEN,

      account_media_preview_url: avatar,
      account_information_blood_type: blood,
      account_information_biography_th: bioTh,
      account_information_biography_en: bioEn,
      account_information_dob: birthday,
      account_information_height: height,
      account_information_weight: weight,
    } = account_informations ?? {}
    const teamsId = [
      ...team_club?.team_id ? [team_club?.team_id] : [],
      ...team_national?.team_id ? [team_national?.team_id] : []
    ] as number[]

    const {
      account_social_contact_email: email,
      account_social_contact_facebook: facebook,
      account_social_contact_fax: fax,
      account_social_contact_instagram: instagram,
      account_social_contact_line: line,
      account_social_contact_twitter: twitter,
      account_social_contact_website: website,
      account_social_contact_youtube: youtube
    } = social_contacts ?? {}

    const medical = midical_conditions?.map(medi => {
      const tmp: { [key: string]: { th: string, en: string } } = {}
      tmp[medi.account_medical_condition_type] = {
        th: medi?.account_medical_condition_name_th ?? '-',
        en: medi?.account_medical_condition_name_en ?? '-',
      }
      return tmp
    }) as any



    return {
      id: account_id,
      name: {
        en: `${firstName ?? ''} ${lastName ?? ''}`,
        th: `${firstNameTh ?? ''} ${lastNameTh ?? ''}`,
      },
      age: age ?? '-',
      nationallity: {
        th: nationallity ?? '-',
        en: nationalityEN ?? '-'
      },
      avatar,
      positions: [...positions?.map(p => {
        const { global_config_position_abbreviation: ab, global_config_position_main_position: main, global_config_position_name_en, global_config_position_name_th } = p
        const tmp = {} as { [key: string]: string }
        tmp[ab ?? global_config_position_name_en?.charAt(0) ?? '_'] = global_config_position_name_th
        return tmp ?? ''
      }) ?? []],
      department: departments,
      team: {
        club: {
          en: team_club?.setting_team_information_name_en ?? '-',
          th: team_club?.setting_team_information_name_th ?? '-',
        },
        national: {
          en: team_national?.setting_team_information_name_en ?? '-',
          th: team_national?.setting_team_information_name_th ?? '-',
        },
        logo: {
          club: team_club?.setting_team_media_profile_url,
          national: team_national?.setting_team_media_profile_url
        }
      },
      teamsId,
      congential: midical_conditions?.find((p: any) =>
        p?.account_medical_condition_type === "CONGENTIAL_DISEASE"
      ),
      food: midical_conditions?.find((p: any) =>
        p?.account_medical_condition_type === "FOOD_ALLERGY"
      ),
      drug: midical_conditions?.find((p: any) =>
        p?.account_medical_condition_type === "DRUG_ALLERGY"
      ),
      blood: blood ?? '-',
      social_contacts: {
        email: email ?? '-', facebook: facebook ?? '-', fax: fax ?? '-', instagram: instagram ?? '-', line: line ?? '-', twitter: twitter ?? '-', website: website ?? '-', youtube: youtube ?? '-',
      },
      account_education: account_education ?? [],
      bio: {
        en: bioEn ?? 'No Data',
        th: bioTh ?? 'ไม่มีข้อมูล',
      },
      birthday: birthday as any,

      height,
      weight
    } as any
  }
  const formatStaffResult = ({ data: results }: BaseResponse<BasePagination<Staff[]>>) => {
    const { data: staff, ...others } = results.account as any
    return {
      account: {
        ...others,
        data: results.account.data?.map(cb) ?? []
      }
    }
  }
  const { run: getStaffRepositories, loading } = useRequest(
    staffService.list,
    {
      manual: true,
      formatResult: formatStaffResult,
      onSuccess: (formattedData) => {
        repositories.value = formattedData.account.account
      }
    }
  )

  const { run: getStaff } = useRequest(
    staffService.getById,
    {
      manual: true,
      formatResult: (result) => {
        const { account } = result.data
        return cb(account)
      }
    }
  )

  const { run: getImages } = useRequest(
    staffService.getImages, {
    manual: true,
    formatResult: ({ data: result }) => {
      const { media } = result
      const { data, ...others } = media
      if (!data?.length) {
        return null
      }
      const tmp = data.map(ele => {
        return {
          src: ele.account_media_url,
          alt: ele.account_media_name
        } as IImage
      })
      return {
        medias: {
          ...others, data: [...tmp]
        }
      } as unknown as BasePagination<IImage[]>
    }
  }
  )

  const { run: getVideos } = useRequest(
    staffService.getVideos, {
    manual: true,
    formatResult: ({ data: result }) => {
      const { media } = result
      const { data, ...others } = media
      if (!data?.length) {
        return null
      }
      const tmp = data.map(ele => {
        return {
          src: ele.account_media_url,
          alt: ele.account_media_name
        } as IVideo
      })

      return {
        medias: {
          ...others, data: [...tmp]
        }
      } as unknown as BasePagination<IVideo[]>
    }
  }
  )

  const formatHonor = (data: any) => {
    const { data: honor, ...others } = data

    const tmp = honor.map((ele: any) => {
      return {
        honorYear: ele.honor_year,
        en: {
          honorName: ele.honor_name_en,
          teamName: ele.setting_team_name_en,
          competitionName: ele.setting_sub_competition_name_en
        },
        th: {
          honorName: ele.honor_name,
          teamName: ele.setting_team_name_th,
          competitionName: ele.setting_sub_competition_name_th
        },
      } as IHonor
    })
    return {
      nation: {
        ...others,
        data: [...tmp]
      }
    } as unknown as any
  }

  const { run: getHonorNation } = useRequest(
    staffService.getHonorNation, {
    manual: true,
      formatResult: ({ data }) => {
      return formatHonor(data.honor)
    }
  }
  )

  const { run: getHonorClub } = useRequest(
    staffService.getHonorClub, {
    manual: true,
    formatResult: ({ data }) => {
      return formatHonor(data.honor)
    }
  }
  )

  const { run: getHonorPersonal } = useRequest(
    staffService.getHonorPersonal, {
    manual: true,
    formatResult: ({ data }) => {
      return formatHonor(data.honor)
    }
  }
  )

  const formatPenalties = (data: any) => {
    const { data: penalty, ...others } = data
    const tmp = penalty.map((ele: any) => {
      return {
        en: {
          name: ele.account_penalty_title_en,
          competition: ele.account_penalty_competition_name_en,
          match: ele.account_penalty_competition_match_en,
          appeal: ele.account_penalty_appeal_begin !== null ? `Appealing` : `No Appealing`
        },
        th: {
          name: ele.account_penalty_title_th,
          competition: ele.account_penalty_competition_name,
          match: ele.account_penalty_competition_match,
          appeal: ele.account_penalty_appeal_begin !== null ? `มีการยื่นอุทธรณ์` : `ไม่มีการยื่นอุทธรณ์`
        },
        date: ele.account_penalty_date,
        duration: ele.account_penalty_duration,

      }
    })

    return {
      penalty: {
        ...others,
        data: [...tmp]
      }
    } as unknown as any
  }

  const { run: getPenaltiesCurrent } = useRequest(
    staffService.getPenaltyCurrent, {
    manual: true,
    formatResult: ({ data }) => {
      return formatPenalties(data.penalty)
    }
  }
  )

  const { run: getPenalties } = useRequest(
    staffService.getPenalty, {
    manual: true,
    formatResult: ({ data }) => {
      return formatPenalties(data.penalty)
    }
  }
  )

  const formatTeamHistory = (data: any) => {
    const { data: history, ...others } = data

    return {
      history: {
        ...others,
        data: history
      }
    }
  }

  const { run: getTeamHistoryNation } = useRequest(
    staffService.getTeamHistoryNation, {
    manual: true,
    formatResult: ({ data }) => {
      return formatTeamHistory(data)
    }
  }
  )
  const { run: getTeamHistoryClub } = useRequest(
    staffService.getTeamHistoryClub, {
    manual: true,
      formatResult: ({ data }) => {
        return formatTeamHistory(data)
      }
  }
  )

  return {
    repositories,
    loading,
    getStaffRepositories,
    getStaff,
    getImages,
    getVideos,
    getHonorNation,
    getHonorClub,
    getHonorPersonal,
    getPenaltiesCurrent,
    getPenalties,
    getTeamHistoryNation,
    getTeamHistoryClub
  }
}
