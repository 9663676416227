import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14d0c29e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "custom-slick-arrow rounded-circle",
  style: {"left":"-25px"}
}
const _hoisted_2 = {
  class: "custom-slick-arrow rounded-circle",
  style: {"right":"-25px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_outlined = _resolveComponent("left-outlined")!
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_ctx.totalItem >= _ctx.slideToShow)
    ? (_openBlock(), _createBlock(_component_a_carousel, {
        key: 0,
        "after-change": _ctx.onSlideChange,
        "slides-to-show": _ctx.slideToShow,
        arrows: "",
        class: _normalizeClass(`slide-length-${_ctx.slideToShow}`),
        dots: false
      }, {
        prevArrow: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_left_outlined)
          ])
        ]),
        nextArrow: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_right_outlined)
          ])
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["after-change", "slides-to-show", "class"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["row", `row-cols-${_ctx.slideToShow}`])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2))
}