import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { notification } from 'ant-design-vue'
import { useCookie } from '@vue-composable/cookie'

const instance = axios.create({ baseURL: '/' })

instance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const { cookie } = useCookie(process.env.VUE_APP_COOKIE_KEY)
    // Do something before request is sent
    // like. Read access token from local storage?
        const token: any = cookie.value
        localStorage.setItem('token', token)

    config.headers.common.Authorization = `Bearer ${token}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error: AxiosError) {
    const { response } = error
    const { removeCookie } = useCookie(process.env.VUE_APP_COOKIE_KEY)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorObj: any = error.toJSON()
    if (response) {
      const { data, status } = response
      if (status >= 500) {
        notification.error({
          message: 'Something went wrong!',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          description: `${errorObj.message!}`
        })
      } else if (status === 401) {
        removeCookie(process.env.VUE_APP_COOKIE_KEY)
      }
      return Promise.reject(data)
    }
    return Promise.reject(errorObj) // Promise.reject(error);
  }
)

export { instance as axios }
