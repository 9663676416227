
import { ICompetition } from "@/_modules/types";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  props: {
    data: Object as PropType<ICompetition>,
  },
  setup() {
    const { t } = useI18n({
      useScope: "global",
    });
    return {
      t,
    };
  },
});
