import { axios } from '@/_modules/axios'
import { BaseResponse, LastestMatch, CompetitionOverviewQuery, TeamDetail, TeamQueryDto, QueryParams, BasePagination, Athlete, AtheteQueryOverviewDto } from '@/_modules/types'

const getLatestMatch = async (params?: CompetitionOverviewQuery): Promise<BaseResponse<LastestMatch>> => {
  // const params = { matches: true, latest: false }
  const { data } = await axios.get<BaseResponse<LastestMatch>>('/api/v1/main/competitions', { params })
  return data
}

const getLastestAthlete = async (params?: AtheteQueryOverviewDto): Promise<BaseResponse<BasePagination<Athlete[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<Athlete[]>>>(`/api/v1/players`, { params })
  return data
}

const getLastestStaff = async (params?: QueryParams): Promise<BaseResponse<BasePagination<Athlete[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<Athlete[]>>>(`/api/v1/staffs`, { params })
  return data
}

const getLastestTeam = async (params?: TeamQueryDto): Promise<BaseResponse<BasePagination<TeamDetail[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<TeamDetail[]>>>(`/api/v1/teams`, { params })
  return data
}


export const homeService = {
  getLatestMatch,
  getLastestAthlete,
  getLastestStaff,
  getLastestTeam
}
