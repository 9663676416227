import { axios } from '@/_modules/axios';
import {
  BaseResponse,
  Athlete,
  QueryParams,
  FootballPosition,
  BasePagination,
  AthleteQueryDto,
  AthleteInformation,
  AthleteTeamHistory,
  AthleteHonors,
  InjuriesCurrent,
  AthletePenaltiesCurrent,
  AthleteMedia
} from '@/_modules/types';

interface AthleteParam extends QueryParams {
  injuries?: boolean;
}
const list = async (
  params?: AthleteParam,
  filter?: FootballPosition
): Promise<BaseResponse<Athlete[]>> => {
  if (filter) {
    let promise;
    switch (filter) {
      case FootballPosition.goalKeeper:
        promise = axios.get<BaseResponse<Athlete[]>>(
          '/api/v1/player/overview/goalkeeper',
          { params }
        );
        break;
      case FootballPosition.defender:
        promise = axios.get<BaseResponse<Athlete[]>>(
          '/api/v1/player/overview/defender',
          { params }
        );
        break;
      case FootballPosition.midFielder:
        promise = axios.get<BaseResponse<Athlete[]>>(
          '/api/v1/player/overview/midfielder',
          { params }
        );
        break;
      case FootballPosition.forward:
        promise = axios.get<BaseResponse<Athlete[]>>(
          '/api/v1/player/overview/forward',
          { params }
        );
        break;
      default:
        promise = Promise.reject(new Error('Type not found!'));
        break;
    }
    const { data } = await promise;
    return data;
  }
  const { data } = await axios.get<BaseResponse<Athlete[]>>('/api/v1/players', {
    params
  });
  return data;
};

const overview = async (
  params: AthleteQueryDto
): Promise<BaseResponse<BasePagination<Athlete[]>>> => {
  const { data } = await axios.get<BaseResponse<BasePagination<Athlete[]>>>(
    'api/v1/player/overview',
    { params }
  );
  return data;
};

const overviewByTeam = async (
  teamId: number,
  filter: FootballPosition,
  params: QueryParams
): Promise<BaseResponse<BasePagination<Athlete[]>>> => {
  const position = [
    { gk: 'goalkeeper' },
    { df: 'defender' },
    { mf: 'midfielder' },
    { fw: 'forward' }
  ].find(ele => ele[filter]) ?? { gk: 'goalkeeper' };
  const { data } = await axios.get<BaseResponse<BasePagination<Athlete[]>>>(
    `api/v1/player/overview/team/${teamId}/${position[filter]}`,
    { params }
  );
  return data;
};

const getBanner = async (accountId: number): Promise<BaseResponse<Athlete>> => {
  const { data } = await axios.get<BaseResponse<Athlete>>(
    `/api/v1/player/${accountId}/banner`
  );
  return data;
};

const getAthleteInjuries = async (params?: AthleteParam) => {
  const { data } = await axios.get<BaseResponse<Athlete[]>>(
    `api/v1/player/overview/injuries/all`,
    { params }
  );
  return data
}

const getInformation = async (
  accountId: number
): Promise<BaseResponse<AthleteInformation>> => {
  const { data } = await axios.get<BaseResponse<AthleteInformation>>(
    `/api/v1/player/${accountId}/information`
  );
  return data;
};

const getTeamHistoryNation = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteTeamHistory>> => {
  const { data } = await axios.get<BaseResponse<AthleteTeamHistory>>(
    `api/v1/player/${accountId}/team_history/nation`,
    { params }
  );
  return data;
};

const getTeamHistoryClub = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteTeamHistory>> => {
  const { data } = await axios.get<BaseResponse<AthleteTeamHistory>>(
    `api/v1/player/${accountId}/team_history/club`,
    { params }
  );
  return data;
};

const getHonorsNation = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteHonors>> => {
  const { data } = await axios.get<BaseResponse<AthleteHonors>>(
    `api/v1/player/${accountId}/honors/nation`,
    { params }
  );
  return data;
};

const getHonorsClub = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteHonors>> => {
  const { data } = await axios.get<BaseResponse<AthleteHonors>>(
    `api/v1/player/${accountId}/honors/club`,
    { params }
  );
  return data;
};

const getHonorsPersonal = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteHonors>> => {
  const { data } = await axios.get<BaseResponse<AthleteHonors>>(
    `api/v1/player/${accountId}/honors/personal`,
    { params }
  );
  return data;
};

const getInjuriesCurrent = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<InjuriesCurrent>> => {
  const { data } = await axios.get<BaseResponse<InjuriesCurrent>>(
    `api/v1/player/${accountId}/injuries/current`,
    { params }
  );
  return data;
};

const getInjuries = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<InjuriesCurrent>> => {
  const { data } = await axios.get<BaseResponse<InjuriesCurrent>>(
    `api/v1/player/${accountId}/injuries`,
    { params }
  );
  return data;
};

const getPenaltiesCurrent = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthletePenaltiesCurrent>> => {
  const { data } = await axios.get<BaseResponse<AthletePenaltiesCurrent>>(
    `api/v1/player/${accountId}/penalties/current`,
    { params }
  );
  return data;
};

const getPenalties = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthletePenaltiesCurrent>> => {
  const { data } = await axios.get<BaseResponse<AthletePenaltiesCurrent>>(
    `api/v1/player/${accountId}/penalties`,
    { params }
  );
  return data;
};

const getVideos = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteMedia>> => {
  const { data } = await axios.get<BaseResponse<AthleteMedia>>(
    `api/v1/player/${accountId}/videos`,
    { params }
  );
  return data;
};

const getImages = async (
  accountId: number,
  params: QueryParams
): Promise<BaseResponse<AthleteMedia>> => {
  const { data } = await axios.get<BaseResponse<AthleteMedia>>(
    `api/v1/player/${accountId}/images`,
    { params }
  );
  return data;
};

export const athleteService = {
  list,
  getBanner,
  overview,
  overviewByTeam,
  getInformation,
  getTeamHistoryNation,
  getTeamHistoryClub,
  getHonorsNation,
  getHonorsClub,
  getHonorsPersonal,
  getInjuriesCurrent,
  getInjuries,
  getPenaltiesCurrent,
  getPenalties,
  getVideos,
  getImages,
  getAthleteInjuries
};
