
import { defineComponent, onMounted, ref, computed, watch } from "vue";
// import { HTMLVideoElement } from 'typescript/lib'
export default defineComponent({
  props: {
    source: String,
    poster: String,
    style: String,
  },
  setup(props) {
    const videoPlayer = ref();
    const videoSrc = computed(() => props.source);
    const videoPoster = computed(() => props.poster);
    onMounted(() => {
      // console.log("video", videoPlayer.value);
    });
    // watch(videoSrc, () => {
    //   if (videoPlayer.value) {
    //     videoPlayer.value.load();
    //   }
    // });
    return {
      videoPlayer,
      videoSrc,
      videoPoster,
    };
  },
});
