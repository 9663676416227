import { axios } from '@/_modules/axios';
import {
  BaseResponse,
  TeamInfo,
  BasePagination,
  TeamDetail,
  TeamQueryDto,
  BaseSingleResponse,
  TeamUniform,
  AthleteHonors,
  QueryParams,
  TeamStaff,
  AthleteMedia
} from '@/_modules/types';

const list = async (
  q: TeamQueryDto
): Promise<BaseResponse<BasePagination<TeamDetail[]>>> => {
  const url = '/api/v1/teams/overview';
  const { type, subType, ...params } = q;
  const { data } = await axios.get<BaseResponse<BasePagination<TeamDetail[]>>>(
    url,
    { params }
  );
  if (data.data) {
    return (data.data as unknown) as BaseResponse<BasePagination<TeamDetail[]>>;
  }
  return data;
};

const getBannerById = async (
  id: number
): Promise<BaseSingleResponse<TeamDetail>> => {
  const { data } = await axios.get(`/api/v1/team/${id}/banner`);
  return data.data;
};

const getById = async (id: number): Promise<BaseSingleResponse<TeamDetail>> => {
  const { data } = await axios.get(`/api/v1/team/${id}/information`);
  return data.data;
};

const getUniform = async (
  id: number,
  year: number
): Promise<BaseSingleResponse<TeamUniform>> => {
  const { data } = await axios.get(`/api/v1/team/${id}/uniform`);
  return data.data;
};

const getPlayerHistory = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(`/api/v1/team/${id}/player/history`, {
    params
  });
  return data;
};

const getPlayerCurrent = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(`/api/v1/team/${id}/player/current`, {
    params
  });
  return data.data;
};

const getPlayerHistoryGoalkeeper = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/history/goalkeeper`,
    {
      params
    }
  );
  return data;
};

const getPlayerHistoryDefender = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/history/defender`,
    { params }
  );
  return data;
};

const getPlayerHistoryMidfielder = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/history/midfielder`,
    {
      params
    }
  );
  return data;
};

const getPlayerHistoryForward = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/history/forward`,
    { params }
  );
  return data;
};

const getTeamsStaffCurrent = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<TeamStaff>> => {
  const { data } = await axios.get(`api/v1/teams/${id}/staff/current`, {
    params
  });
  return data.data;
};

const getTeamsStaffHistory = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<TeamStaff>> => {
  const { data } = await axios.get(`api/v1/teams/${id}/staff/history`, {
    params
  });
  return data.data;
};

const getTeamsMatchHistoryImportant = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<TeamStaff>> => {
  const { data } = await axios.get(
    `api/v1/teams/${id}/match/history/important`,
    {
      params
    }
  );
  return data.data;
};

const getTeamsMatchHistory = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<TeamStaff>> => {
  const { data } = await axios.get(`api/v1/teams/${id}/match/history`, {
    params
  });
  return data.data;
};

const getTeamsMediaImages = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteMedia>> => {
  const { data } = await axios.get(`api/v1/teams/${id}/media/images`, {
    params
  });
  return data;
};

const getTeamsMediaVideos = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteMedia>> => {
  const { data } = await axios.get(`api/v1/teams/${id}/media/videos`, {
    params
  });
  return data;
};

// /api/v1/teams/overview
const getTeamsOverview = async (
  params: QueryParams
): Promise<BaseResponse<TeamStaff>> => {
  const { data } = await axios.get('api/v1/teams/overview', { params });
  return data.data;
};

const getPlayerCurrentGoalkeeper = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/current/goalkeeper`,
    {
      params
    }
  );
  return data;
};

const getPlayerCurrentDefender = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/current/defender`,
    {
      params
    }
  );
  return data;
};

const getPlayerCurrentMidfielder = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/current/midfielder`,
    {
      params
    }
  );
  return data;
};

const getPlayerCurrentForward = async (
  id: number,
  params: QueryParams
): Promise<BaseSingleResponse<AthleteHonors>> => {
  const { data } = await axios.get(
    `/api/v1/team/${id}/player/current/forward`,
    {
      params
    }
  );
  return data;
};

export const teamService = {
  getById,
  list,
  getBannerById,
  getUniform,
  getPlayerHistory,
  getPlayerHistoryGoalkeeper,
  getPlayerHistoryDefender,
  getPlayerHistoryMidfielder,
  getPlayerHistoryForward,
  getTeamsStaffCurrent,
  getTeamsStaffHistory,
  getTeamsMatchHistoryImportant,
  getTeamsMatchHistory,
  getTeamsMediaVideos,
  getTeamsMediaImages,
  getTeamsOverview,
  getPlayerCurrentGoalkeeper,
  getPlayerCurrentDefender,
  getPlayerCurrentMidfielder,
  getPlayerCurrentForward,
  getPlayerCurrent
};
