
import { defineComponent, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCookie } from "@vue-composable/cookie";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { cookie, setCookie } = useCookie(process.env.VUE_APP_COOKIE_KEY);
    watch(
      () => route.query,
      (queryParams) => {
        // const { query: queryParams } = route
        // console.log('onWatchRoute', queryParams)
        if (queryParams?.token) {
          setCookie(`${queryParams.token}`);
          delete queryParams.token;
          router.replace({
            ...router.currentRoute,
            query: { token: undefined },
          });
          window.history.replaceState({}, document.title, "/");
          setTimeout(() => {
            router.replace({ name: "Overview" });
          }, 1000);
        } else {
          const token = localStorage.getItem("token");
          if (!cookie.value && !token && token !== undefined) {
            router.replace({ name: "Disallowed" });
          } else if (token === undefined) {
            router.replace({ name: "Disallowed" });
          } else {
            router.replace({ name: "Overview" });
          }
        }
      },
      { immediate: true }
    );
  },
});
