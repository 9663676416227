import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App.vue'
import router from './router'
import store from './store'
import enUs from './locales/en-US.json'
import thTh from './locales/th-TH.json'
import Antd from 'ant-design-vue/es'
import '@/style.less'

const i18n = createI18n({
  legacy: false,
  locale: 'th',
  locales: ['th', 'en'],
  fallbackLocale: 'en',
  messages: {
    en: enUs,
    th: thTh
  }
})

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Antd)

app.config.globalProperties.window = window
// app.config.globalProperties.$i18n = () => {}
app.mount('#app')
